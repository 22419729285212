import React from "react"
import PropTypes from "prop-types"
import style from "./layout.module.scss"

const Layout = ({ children }) => {
  const { wrapper } = style
  return <div className={wrapper}>{children}</div>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
