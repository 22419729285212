import React from "react"
import Layout from "../components/layout"
import style from "./terms.module.scss"
const PrivacyTerms = () => {
  const {wrapper,tocList,title,page,tableofcontent} = style
  return (
    <Layout>
      <div className={wrapper}>
      <h1>EveryBuddy Games Privacy Policy</h1>
      <h4>Last updated: June 12, 2022</h4>
      <p>
      EveryBuddy values your privacy. This privacy policy <b>(“Policy”)</b> describes how EveryBuddy Games Ltd. 
      and our affiliates (together, <b>“EveryBuddy”, “we”, “us”, “our”</b>) processes Personal Data 
      (as defined below) which we receive or collect in relation to your access and use of our application 
      and website, everybuddygames.com. Our products and services that we provide , such as application and 
      website (collectively,  “Services”), and other products and services we offer 
      (together with our Website, our <b>“Services”</b>), as part of our marketing practices and from our 
      business contacts (such as representatives of customers and suppliers) and 
      other third parties who provide us with Personal Data. 
      </p>
      <p>The term, <b>“Personal Data”,</b> as used in this Policy
         means information relating to an identified or identifiable natural person.</p>

      <p>The term,<b>“processing”,</b>as used in this Policy means any action taken in respect of Personal Data.</p>
      <p>We may change this Policy from time to time in order to keep you apprised of our data collection and use practices. We encourage you to regularly visit and review this Policy to stay informed of such practices.</p>
      
      <p className={tableofcontent}><b><u>Table of content</u></b></p>

      <ol className={tocList}>
        <li>
          <a href="#The_Personal_Data">
            <span className={title}><b>1.The Personal Data We Collect and How we use it</b></span>
          </a>
          <ol>
            <li>
              <a href="#1.1">
                  <span className={title}>1.1 When you visit our application (automatically collected data)</span>
                </a>
              </li>
              <li>
                <a href="#1.2">
                  <span className={title}>1.2 When you interact with our social media accounts</span>
                </a>
              </li>
              <li>
                <a href="#1.3">
                  <span className={title}>1.3 When you sign up for our push notifications or to receive updates or other communications from us </span>
                </a>
              </li>
              <li>
                <a href="#1.4">
                  <span className={title}>1.4 When you contact us </span>
                </a>
              </li>
              <li>
                <a href="#1.5">
                  <span className={title}>1.5 When you create an account to use and access our Services</span>
                </a>
              </li>
              <li>
                <a href="#1.6">
                  <span className={title}>1.6 When you access and use our Services</span>
                </a>
              </li>
              <li>
                <a href="#1.7">
                  <span className={title}>1.7 When you submit CV during your recruiting process with us</span>
                </a>
              </li>
          </ol>
          <li>
           <a href="#2">
              <span className={title}><b>2.Automatically Collected Information: SDKs and Opt Out Mechanism</b></span>
            </a>
          </li>
          <li>
           <a href="#3">
              <span className={title}>3 How (and with whom) we Share Personal Data</span>
            </a>
            <ol>
              <li>
                <a href="#3.1">
                  <span className={title}>3.1 Cloud and Hosting Services</span>
                </a>
              </li>
              <li>
                <a href="#3.2">
                  <span className={title}>3.2 Law Enforcement or other Government Entities</span>
                </a>
              </li>
              <li>
                <a href="#3.3">
                  <span className={title}>3.3 With your Consent </span>
                </a>
              </li>
              <li>
                <a href="#3.4">
                  <span className={title}>3.4 Disputes </span>
                </a>
              </li>
              <li>
                <a href="#3.5">
                  <span className={title}>3.5 Mergers and Acquisitions </span>
                </a>
              </li>
              <li>
                <a href="#3.6">
                  <span className={title}>3.6 Affiliates</span>
                </a>
              </li>
              <li>
                <a href="#3.7">
                  <span className={title}>3.7 Additional Information on our Service Providers</span>
                </a>
              </li>
          </ol>
          </li>
          <li>
            <a href="#4">
                <span className={title}><b>4. How Long we Retain Personal Data</b></span>
              </a>
          </li>
          <li>
            <a href="#5">
                <span className={title}><b>5. Your GDPR Rights</b></span>
              </a>
          </li>
          <li>
            <a href="#6">
                <span className={title}><b>6. Transfers of Personal Data to other Countries Outside the EU or EEA</b></span>
              </a>
          </li>
          <li>
            <a href="#7">
                <span className={title}><b>7. Your California Privacy Rights</b> </span>
              </a>
                <ol>
                    <li>
                        <a href="#7.1">
                          <span className={title}>7.1 Disclosure of Direct Marketing Practices (“Shine the Light”) </span>
                        </a>
                    </li>  <li>
                        <a href="#7.2">
                          <span className={title}>7.2 Removal of Public Information of Minors </span>
                        </a>
                    </li>
                </ol>
          </li>
          <li>
            <a href="#8">
                <span className={title}><b>8. California Do Not Track Notice</b> </span>
            </a>
          </li>
          <li>
            <a href="#9">
                <span className={title}><b>9. Personal Data of Children </b></span>
            </a>
          </li>
          <li>
            <a href="#10">
                <span className={title}><b>10. Third Party Websites, Services & Data Collection </b></span>
            </a>
          </li>
          <li>
            <a href="#11">
                <span className={title}><b>11. Anonymous Information </b></span>
            </a>
          </li>
          <li>
            <a href="#12">
                <span className={title}><b>12. Our Contact Information </b></span>
            </a>
          </li>
     <ol>
     
    </ol>
  </li>
</ol>
      
      <ol className={tocList}>
        <li id={'The_Personal_Data'}>
            <span className={title}><b>1.The Personal Data We Collect and How We Use It.</b></span>
            <p>
              We collect and receive Personal Data from outside our company and affiliates, such as from users of our products and services as described in the table below. Certain Personal Data is collected automatically, while other Personal Data is provided by you voluntarily, for example, when you contact us. The description in the table may be supplemented by additional information in other parts of this Policy. For example, you may find for more information on third-parties to whom your information may be transferred in Section ‎2 below.
            </p>
         </li>
         <table>
                    <tr>
                        <th>When we Collect Data</th>
                        <th>Type of Personal Data we Collect</th>
                        <th>Purposes of Processing the Data</th>
                        <th>Retention Period (How long we store the data)*</th>
                        <th>Legal Basis under the GDPR</th>
                        <th>Third Parties with Whom We Share your Personal Data**</th>
                        <th>Consequen ces of not Providing the Personal Data</th>
                    </tr>
                    <tr>
                        <td id={"1.1"}><b>1.1 When you download and access our application (automatically collected data</b></td>
                        <td>
                            <ul>
                                <li>SDKs,including third party’s SDKs</li>
                                <li>IP address</li>
                                <li>Apple ID</li>
                                <li>Country</li>
                                <li>Device model</li>
                                <li>Screen model</li>
                                <li>The Identifier for Advertisers (IDFA)</li>
                                <li>Information about your device</li>
                                <li>Other information automatically sent by your device</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Analysis of activity</li>
                                <li>protecting the security of the application</li>
                                <li>Enable application functionality;</li>
                                <li>Identify unique users, track user activity</li>
                                <li>Display and personalization of advertisements
                                    allowing cross device
                                    play with Apple
                                </li>

                            </ul>
                        </td>
                        <td>5 years following the last login date.</td>
                        <td>
                            <ul>
                                <li>Your consent</li>
                                <li>Legitimate interest(e.g.essential cookies)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Google
                                    Analytics
                                </li>
                                <li>Firebase</li>
                                <li>AWS</li>
                                <li>Appsflyer</li>
                                <li>Apple</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li> Depending on the cookie type you may not be able to access or use the Services.
                                </li>
                            </ul>
                        </td>
                    </tr>


                    <tr>
                        <td id={"1.2"}><b>1.2 When you interact with our social media accounts</b></td>
                        <td>
                            <ul>
                                <li>Placement of cookies, including third party cookies</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Respond to any inquiries or requests regarding our Services;</li>
                            </ul>
                        </td>
                        <td>5 years following the last login date</td>
                        <td>
                            <ul>
                                <li>Your consent</li>
                                <li>Legitimate interest</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Google</li>
                                <li>Facebook</li>
                                <li>Apple</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>We would not be able to</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td id={"1.3"}><b>                 
                            1.3 When you
                            receive our
                            push
                            notifications to
                            receive
                            updates,
                            advertising
                            and other
                            communication
                            s from us </b></td>
                        <td>
                            <ul>
                                <li>Push Token
                                    Number
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Send you our push
                                    notification and other
                                    marketing
                                    communications,
                                    based on your profile,
                                    intent and behaviour
                                </li>
                            </ul>
                        </td>
                        <td>5 years
                            following the
                            last login date
                        </td>
                        <td>
                            <ul>
                                <li>Your
                                    consent.
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>AWS</li>
                                <li>Apple</li>
                                <li>Google</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>We will
                                    not be
                                    able to
                                    send you
                                    communic
                                    ations
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td id={"1.4"}><b>
                            1.4 When you
                            contact us </b></td>
                        <td>
                            <ul>
                                <li>Email address</li>
                                <li>User ID</li>
                                <li>Login
                                    information
                                </li>
                                <li>nformation
                                    about your
                                    request or
                                    inquiry, and
                                    any other
                                    information
                                    you provide.
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Verify your identity,
                                    process your request
                                    and respond to or
                                    contact you regarding
                                    your request or
                                    inquiry;
                                </li>
                                <li>Present this
                                    information to you as
                                    part of your account
                                    history
                                </li>
                                <li>Display and contact
                                    you with information
                                    that is relevant to you
                                    in the future, and to
                                    assist you with further
                                    requests for support;
                                </li>
                                <li>Improve our customer
                                    support and
                                    communication
                                    practices.
                                </li>
                            </ul>
                        </td>
                        <td>5 years following the last contact.</td>
                        <td>
                            <ul>
                                <li>The
                                    performan
                                    ce of our
                                    contract
                                    with you (if
                                    you are a
                                    customer).
                                </li>
                                <li>Your Consent
                                </li>
                                <li>Our legitimate interest </li>
                            </ul>
                        </td>
                        <td>
                            <ul><li>Mailchimp</li></ul>
                        </td>
                        <td>
                            <ul>
                                <li>We may not be able to provide you support or respond to your request.</li>
                             </ul>
                        </td>
                    </tr>
                    <tr>
                        <td id={"1.6"}><b>1.6 When use our application</b></td>
                        <td>
                            <ul>
                                <li>Various activity on the</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Provide the Services;</li>
                                <li>Help users at struggle;</li>
                            </ul>
                        </td>
                        <td>5 years following the last login</td>
                        <td>
                            <ul>
                                <li>Your Consent</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>AWS</li>
                                <li>Firebase</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>You
                                    cannot
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
        <li>
          <p><i>
            * Please see Section 2 below for more information about our use of service providers. Section ‎‎2.7 provides for more information about third parties listed above.
            </i></p>
            <p><i>
            ** Please see Section 3 for more information on how long we retain data we collect.
          </i></p>
        </li>
        <li id={'2'}>
            <span className={title}><b>2.Automatically Collected Information: SDKs and Opt Out Mechanism</b></span>
            <p>
               Software Development Kits (shorten as SDKs) are programming packages that allow us to develop the App and designed it to work on or with a specific platform. The Analytics SDK will allow us or our third parties to track and measure certain data about the way users interact with our App. 
            </p>
            <p>
              Analytics SDKs collect information about our App usage including data about many users are using the app, the number of sessions running on an app and how long each session is, from where in the world these users are logged in from, how many times an app is crashing, what operating systems and devices are running the app, the amount of money spent within apps by users, and how many times certain app features are used. By seeing how an app is used with the information gathered through an Analytics SDK, which is annonymized, an app can be accordingly improved (please review other purposes in the table above).
            </p>
            <p>
              Most devices will allow you to erase SDKs and block acceptance of SDKs. You may remove SDKs by following the instructions in your device preferences and the Opt out instructions below:
            </p>
            <p>
              Appsflyer – to opt from Appsflyer, please review the information here: 
              <a href="https://support.appsflyer.com/hc/en-us/articles/360001422989-User-opt-in-opt-out-in-the-AppsFlyer-SDK">https://support.appsflyer.com/hc/en-us/articles/360001422989-User-opt-in-opt-out-in-the-AppsFlyer-SDK. </a>
              </p>
              <p>  
                Facebook – to opt out from Facebook, please review the information here: 
                <a href="https://www.facebook.com/policy/cookies/">https://www.facebook.com/policy/cookies/</a>
              </p>
              <p>
                  Firebase  –   to   opt   from   Firebase,   please   review   the   information   here:
                  <a href="https://firebase.google.com/docs/perf-mon/disable-sdk?platform=ios">https://www.facebook.com/policy/cookies/</a>
              </p>                          
         </li>
      
         <li id={'3'}>
            <span className={title}><b>3.How (and with whom) we Share Personal Data</b></span>
            <p>
               In addition to any methods or third parties with whom we may share your Personal Data described above, we share Personal Data in the following manner: 
            </p>
            <ol>
                        <li id={"3.1"}><strong><u>3.1 Cloud and Hosting Services.</u></strong> We use cloud storage providers to host the Services
                            and store
                            all information we collect. We currently engage Amazon Web Services (or one of its
                            affiliates), for such services and in addition to the third parties listed in Section 1
                            above,
                            copies of all data we collect are stored on their servers.
                        </li>
                        <li id={"3.2"}><strong><u>3.2 Law Enforcement or Other Government Entities</u></strong> . Where required by law or
                            government or
                            court order, we will disclose Personal Data relating to you to the extent we believe we are
                            required by law.
                        </li>
                        <li id={"3.3"}><strong><u>3.3 With your Consent .</u></strong> In the event that you have requested or have consented to
                            the transfer
                            of Personal Data relating to you to a third party (such as by checking a box to signify your
                            agreement) we will transfer Personal Data to the relevant third party.
                        </li>
                        <li id={"3.4"}><strong><u>3.4 Disputes and Legal Advice.</u></strong> In case of any dispute or request with, by or
                            concerning you, we
                            may disclose Personal Data relating to you with our legal counsel, professional advisors and
                            service providers, the relevant court or other tribunal and other third parties as needed in
                            order to resolve the dispute, defend ourselves against any claims, or enforce our rights.
                        </li>
                        <li id={"3.5"}><strong><u>3.5 Mergers and Acquisitions.</u></strong>In the event that we, or a part of our business, are
                            sold to a third
                            party, or if we are merged with another entity, or in the event of bankruptcy or a similar
                            event, we will transfer information about the relevant portions of our business as well as
                            relevant customers and users and other personal data, to the purchaser or the entity with
                            which we are merged. We may also transfer personal data to a potential acquirer, their
                            legal counsel or service providers as part of an evaluation or due diligence review of our
                            company in anticipation of an investment, acquisition or merger, though such transfers do
                            not typically include personal data and are subject to obligations of confidentiality.
                        </li>
                        <li id={"3.6"}><strong><u>3.6 Affiliates.</u></strong>  We share personal data with our affiliates, who assist us in
                            processing personal
                            data and providing our Services.
                        </li>
                        <li id={"3.7"}><strong><u>3.7.Additional information on our Service Providers (listed in Section 1).</u></strong> 
                         <p>
                             To help you understand where data about you is transferred, here is more information about the service providers listed above whom we engage to process Personal Data that we collect. Where such service providers have made available information about their data security or processing practices which we think is relevant to you, we have provided links to such information. We take no responsibility for information found on their websites, including, without limitation, whether it is accurate or up-to-date.  
                          </p>
                          <p>
                            Please note that the listing of these service providers in this Privacy Policy is not a guarantee or undertaking that only these service providers will be used. We may change service providers or add news ones. We make commercial reasonable efforts to update this Privacy Policy to reflect these changes. 
                          </p>
                            <ul>
                              <li><b>Amazon Web Services</b> is a provider of the website hosting provider. For more
                                  information about how Amazon handles data stored on behalf of its customers like us
                                  visit <a href="https://aws.amazon.com/compliance/data-privacy-faq/">https://aws.amazon.com/compliance/data-privacy-faq/.</a>
                              </li>
                              <li><b>Facebook, Inc.</b> and Instagram, provides us with analytics tools known as the
                                  Facebook Pixel, which collects information about your use of our Website. You can find
                                  out more about how Facebook uses Personal data at
                                  <a href="https://www.facebook.com/full_data_use_policy">https://www.facebook.com/full_data_use_policy</a>
                              </li>
                              <li><b>Google LLC </b> and its affiliates provide us with services such as Google Analytics
                                  (for
                                  embedding videos on our Website). You can read more about Google’s policies in
                                  connection with data received from its customers at:
                                  <a href="https://policies.google.com/privacy. ">https://policies.google.com/privacy.</a>
                              </li>
                              <li><b>AppsFlyer </b> is a provider of a mobile marketing analytics and attribution platform
                                  which we use to determine the track user interaction with advertisements on our
                                  Services and analyze user behavior on our Services. For more information on how
                                  Appsflyer handles end user data, please see its Services Privacy Policy:
                                  <a href="https://www.appsflyer.com/legal/privacy-policy/">https://www.appsflyer.com/legal/privacy-policy/.</a>
                              </li>
                              <li><b>Apple </b> provides us with app-store platform, personalization services and account
                                  synchronization between devices. You can read more about Apple's policies in
                                  connection with data received from its customers a
                                  <a href="https://www.apple.com/legal/privacy/">
                                      https://www.apple.com/legal/privacy/.</a>
                              </li>
                          </ul>
                        </li>
                        <li id={'4'}>
                          <span className={title}><b>4.How Long We Retain Personal Data</b></span>
                          <p>
                            We may store Personal Data for longer periods of time than stated in Section ‎1 above: (i) where we believe we are required to do so to comply with laws or in connection with legal proceedings; (ii) to resolve disputes; (iii) to enforce agreements between us and others; (iv) when the information is related to a potential or actual legal dispute. 
                          </p>
                          <p>
                            Please note that if we receive Personal Data relating to you in multiple contexts, such as if you have separately provided information or we have another basis to use it, then we may keep such information for the longer of the 
                          </p>
                          <p>
                            periods listed in this Privacy Policy. For example, you are a customer or are a representative of a customer who has subscribe to receive our push notifications or marketing emails but later unsubscribes, then we will retain such information for the period listed in connection with customer or business contact information as described in this Privacy Policy, even if we remove you from the applicable email list.
                          </p>
                        </li>
                        <li id={'5'}>
                          <span className={title}><b>5.Your GDPR Rights.</b></span>
                          <p>
                             Subject to certain exceptions and exclusions, the following rights apply to individuals who are located in an EU member state or otherwise protected by the EU General Data Protection Regulation (“GDPR”), as further described below. If you are such a person, then:
                          </p>
                          <ul>
                            <li><b><u>right of Access.</u></b> You may request that we confirm to you whether or not we store
                                Personal
                                Data about you and to receive a copy of the Personal Data we maintain about you and
                                information about: (a) the purposes of the processing of the Personal Data; (b) the
                                categories of Personal Data being processed; (c) the names of the recipients or the
                                categories of recipients to whom the Personal Data have been or will be disclosed, in
                                particular recipients in third countries or international organizations; (d) if
                                possible, the
                                period we believe we will store the Personal Data, or the criteria we use to determine
                                that
                                period; (e) the sources of the Personal Data, if not collected from you; and (f) whether
                                we
                                use automated decision-making, including profiling, and meaningful information about the
                                logic involved, as well as the significance and the envisaged consequences of such
                                processing for you.
                            </li>
                            <li><b><u>right to Rectify.</u></b> You may request that we correct any Personal Data about you
                                that we
                                maintain that is incorrect. Depending on the purpose for which the data is used, you may
                                also request to complete incomplete Personal Data we maintain
                            </li>
                            <li><b><u> right to Erasure (“Right to be Forgotten”).</u></b> You may request that we erase or
                                suppress
                                Personal Data that relates to you in the following cases: the data is no longer needed
                                by us;
                                the data was collected and is used on the basis of your consent and you withdraw that
                                consent; when you have a right to object to our use of the data (as described below
                                under,
                                “Right to Object”); we are not lawfully processing the data; or we are otherwise
                                required by
                                law to delete the data. However, there may be circumstances in which we may retain your
                                data or we may refuse your request, for example, when we review the data to defend
                                ourselves or make legal claims or exercise are own rights. In addition, this right may
                                not
                                apply to the display or access of your Personal Data outside of the European Union
                            </li>
                            <li><b><u>Right to Restrict Processing.</u></b> 
                                <p>
                                  You may request that we restrict our use or
                                  processing of your
                                  Personal Data if: you claim the Personal Data is inaccurate, during the time we
                                  investigate
                                  your claim; our processing of the Personal Data was unlawful; we no longer require the
                                  Personal Data; we processed the Personal Data for our legitimate interests and you
                                  object to
                                  this use (as you are permitted to do under Article 21(1) of the GDPR), during the time
                                  that we investigate whether our legitimate interests override your request. However,
                                  there may
                                  be circumstances in which we are legally entitled to refuse your request
                                </p>
                            </li>
                            <li><b><u>Right to Data Portability</u></b> 
                                <p>
                                you may request that we provide you with your Personal
                                Data that
                                we process based on your consent or to fulfill a contract with you or that we process
                                using
                                automated means, in a structured, commonly used and machine-readable format, and to
                                transfer your Personal Data to another entity or have us transfer it directly to such
                                entity.
                                </p>
                            </li>
                            <li><b><u>Right to Object.</u></b> 
                                <p>
                                  You may, based on reasons specifically relating to you, object
                                  to our processing of your Personal Data, when: (i) the data is used for our legitimate
                                  interests and
                                  our interests in processing the data does not override your interests, rights and
                                  freedoms
                                  and we do not require use of the data for the establishment, exercise or defense of our
                                  legal
                                  claims or rights; and (ii) we use the data for direct marketing purposes or profiling
                                  for direct marketing purposes
                                </p>
                            </li>
                            <li>
                                <b><u>Right to Object to Automated Decision Making.</u></b> 
                                <p>
                                  You may request that you not to be subject
                                  to a decision based solely on automated processing, including profiling, when the
                                  decision produces legal effects concerning you or significantly affects you.
                                </p>
                            </li>
                            <li>
                              <b><u>Right to Make a Complaint.</u></b> You may file a complaint regarding our practices with
                                the data
                                protection authority in your place of habitual residence, place or work, or the place of
                                the
                                alleged infringement. For a list of data protection authorities in the European Economic
                                Area,
                                please see here:<a href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080."> https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080.</a>
                                <p>
                                  You can exercise your rights that apply to us by contacting us by email at
                                  privacy@everybuddygames.com. We may be permitted by law (including the GDPR and local
                                  national laws) to refuse or limit the extent to which we comply with your request. We
                                  may also
                                  require additional information in order to comply with your request, including
                                  information
                                  needed to locate the information requested or to verify your identity or the legality of
                                  your
                                  request. To the extent permitted by applicable law, we may charge an appropriate fee to
                                  comply with your request.
                                </p>
                            </li>
                        </ul>
                     </li>
                 </ol>
                 <li id={'6'}>
                          <span className={title}><b>6.Transfers of Personal Data to other Countries outside the EU or EEA</b></span>
                          <p>
                              We transfer Personal Data we receive in or from the European Union 
                              (and other locations) to the following 
                              countries outside the EU and European Economic Area.
                              If Personal Data from the EU is transferred outside the EU to our
                              affiliates or to third-party service providers, to countries which the 
                              European Commission has not determined to adequately protect Personal Data,
                              we take steps to ensure that such Personal Data receives the same level of 
                              protection as if it remained within the EU. This includes entering into data 
                              transfer agreements and using the European Commission approved Standard 
                              Contractual Clauses.
                          </p>                     
                          <ul>
                            <li>Israel, is where we are located. As of the date of this Policy, the European Commission
                                has
                                determined that the State of Israel adequately protects Personal Data. (A list of
                                countries
                                which the European Commission has determined to adequately protect Personal Date can be
                                found, as of the date of this Policy, here: <a href="https://ec.europa.eu/info/law/law-topic/data-
                            protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-
                            countries_en">https://ec.europa.eu/info/law/law-topic/data-
                                    protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-
                                    countries_en).</a>
                            </li>
                            <li>
                                The United States, is where Amazon Web Services, Google, Facebook and Apple store
                                information they receive from us.
                            </li>
                            <li>
                                Ireland and Belgium are where Appsflyer stores information they receive from us.
                            </li>
                            <p>
                                  If you are an individual protected by the GDPR, you may contact us in order to obtain
                                  additional
                                  information regarding the basis for the transfer of Personal Data relating to you to
                                  countries
                                  outside the European Economic Area. 
                                  Please note that information or copies of documents
                                  we may provide to you in connection with such requests may be limited or redacted in order
                                  to protect the rights of third parties or to comply with contractual obligations we may
                                  have (such as obligations of confidentiality)
                             </p>
                        </ul>
                  </li>
            </li>
            <li id={'7'}>
                  <span className={title}><b>7.Your California Privacy Rights </b></span>
                  <p>
                    If you are a California resident you have the right under California law to make certain requests in connection with our use of Personal Data relating to you, as described below. To make such a request, please contact us by email at privacy@everybuddygames.com. Please note that certain exceptions may apply.
                  </p>
                  <ol>
                        <li id={"7.1"}><strong><u>7.1 Disclosure of Direct Marketing Practices (“Shine the Light”).</u></strong>
                             <p>
                              Under California Civil Code Section 1798.83, 
                              one time per year you may request the following information 
                              regarding our disclosure of your Personal Data to third parties for 
                              their direct marketing purposes: a list of the categories of the personal 
                              information disclosed to such parties during the preceding calendar year, 
                              the names and addresses of such third parties, and if the nature of the parties’ 
                              businesses is not clear from their names, examples of the products or services 
                              marketed by such third parties. 
                              This right only applies if our relationship is 
                              primarily for your personal, family or household purposes and related 
                              to the purchase of our products and services.
                             </p>
                        </li>
                        <li id={"7.2"}><strong><u>7.2 Removal of Public Information of Minors.</u></strong>
                            <p>
                              We use cloud storage providers to host the Services
                              and store all information we collect. We currently engage Amazon Web Services (or one of its
                              affiliates), for such services and in addition to the third parties listed in Section 1
                              above,copies of all data we collect are stored on their servers.
                            </p>
                        </li>
                  </ol>
            </li>
            <li id={'8'}>
                  <span className={title}><b>8.California Do Not Track Notice.</b></span>
                  <p> We do not track individuals’ online activities over time and across third-party web
                      sites or online services (though we do receive information about the webpage you visited prior to access
                      our websites, products and services such as our Website, social media accounts, and
                      advertisement
                      landing pages). We do permit third-parties to track individuals’ online activities on
                      our Site and App, including Google and Facebook which provide us with the analytics and tracking
                      services
                      described above. We do not respond to Web browser “do not track” signals or similar
                      mechanisms. You can find out more information about do not track signals at: <a
                      href="http://www.allaboutdnt.com/.">http://www.allaboutdnt.com/.</a>
                    </p>
            </li>
            <li id={'8'}>
                  <span className={title}><b>9.Third Party Websites, Services & Data Collection</b></span>
                  <p>Our Services may contain links to other websites, products or services offered by third
                                parties
                                (“Third Party Services”). This Policy does not apply to data collected by or on behalf
                                of such
                                third parties, whose privacy practices may differ from ours and who are not under our
                                control.
                                We are not responsible for the actions of such third parties or their data practices.
                                Please review
                                the privacy policies of any such third parties before you interact with them or provide
                                them with
                                Personal Data.
                                Furthermore, we are not responsible for the accuracy of information contained on Third
                                Party
                                Services linked to in this privacy policy or from our Services. We refer to such
                                websites for your
                                convenience only.
                            </p>
            </li>
            <li id={'10'}>
                  <span className={title}><b>10.Personal Data of Children.</b></span>
                  <p>
                   Our Services are not intended for, and we do not knowingly collect Personal Data from persons under the age of eighteen (18). If you believe that a person under the age of sixteen (16) has provided us with Personal Data, or if we have received the Personal Data of such person, please contact us at support@everybuddygames.com.
                  </p>
             </li>     
             <li id={'11'}>
                  <span className={title}><b>11.Anonymous Information.</b></span>
                  <p>
                    We do not treat information we collect or receive which is not or cannot reasonably be connected to any particular person or which is anonymized or aggregated such that it can no longer be connected to or used to identify any particular person as “Personal Data”, even if it was originally linked to or stored with Personal Data. Such anonymous information is not subject to this Policy and we may use it for a variety of purposes, may share it with third parties or even publish it for any reason.
                  </p>
              </li>
              <li id={'12'}>
                  <span className={title}><b>12.Our Contact Information</b></span>
                  <ul>
                    <li>Address: EveryBuddy Games, Ltd. Weizmann 4 St, </li>
                    <li>Tel-Aviv, 6423904 Israel</li>
                    <li>Email: Privacy@everybuddygames.com.</li>
                  </ul>
              </li>
        </ol>
     </div>
    </Layout>
  )
}

export default PrivacyTerms
